import React, { useState, useEffect, useMemo } from "react"
import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"
import Dialog from "@material-ui/core/Dialog"
import ButtonBase from "@material-ui/core/ButtonBase"
import ImagemIconeFechar from "../../images/comunicado-final-de-ano-icone-fechar.png"
import "./style.scss"

const SESSION_AVISO_FERIAS = "avisoDeFeriasFechado-2023-2024"

export default ({ data }) => {
  const intl = useIntl()

  const locale = intl.locale || "pt"

  const {
    imagemAvisoFeriasDesktop,
    imagemAvisoFeriasMobile,
    imagemAvisoFeriasMobileHorizontal,
  } = data

  const imagemAvisoDesktop = useMemo(
    () =>
      imagemAvisoFeriasDesktop.nodes.find(
        dataImagem =>
          dataImagem.name === `comunicado-final-de-ano-desktop-${locale}`
      ),
    [imagemAvisoFeriasDesktop, locale]
  )

  const imagemAvisoMobile = useMemo(
    () =>
      imagemAvisoFeriasMobile.nodes.find(
        dataImagem =>
          dataImagem.name === `comunicado-final-de-ano-mobile-${locale}`
      ),
    [imagemAvisoFeriasMobile, locale]
  )

  const imagemAvisoMobileHorizontal = useMemo(
    () =>
      imagemAvisoFeriasMobileHorizontal.nodes.find(
        dataImagem =>
          dataImagem.name ===
          `comunicado-final-de-ano-mobile-horizontal-${locale}`
      ),
    [imagemAvisoFeriasMobileHorizontal, locale]
  )

  const [exibeAviso, setExibeAviso] = useState(false)

  useEffect(() => {
    // se ja fechou o aviso nessa sessão, então n abre novamente
    if (sessionStorage.getItem(SESSION_AVISO_FERIAS)) return

    setExibeAviso(true)
  }, [])

  const fechaAviso = () => {
    // salva flag na sessão para n abrir novamente na mesma sessão
    sessionStorage.setItem(SESSION_AVISO_FERIAS, "1")

    setExibeAviso(false)
  }

  return (
    <Dialog
      onClose={fechaAviso}
      open={exibeAviso}
      classes={{
        paper: "paper-aviso-ferias-mobile",
      }}
      aria-labelledby="aviso-de-ferias"
    >
      <div className="btn-fecha-aviso">
        <ButtonBase
          type="button"
          aria-label={intl.formatMessage({ id: "Fecha aviso" })}
          onClick={fechaAviso}
          focusRipple
        >
          <img src={ImagemIconeFechar} alt="icone fechar" />
        </ButtonBase>
      </div>
      <div className="imagem-fundo-desktop">
        <Img
          fixed={imagemAvisoDesktop?.childImageSharp?.fixed}
          alt="imagem-aviso-ferias"
        />
      </div>
      <div className="imagem-fundo-mobile">
        <Img
          fluid={imagemAvisoMobile?.childImageSharp?.fluid}
          style={{ objectFit: "contain", width: "100%", height: "100%" }}
          imgStyle={{ objectFit: "contain" }}
          alt="imagem-aviso-ferias"
        />
      </div>
      <div className="imagem-fundo-mobile-horizontal">
        <Img
          fluid={imagemAvisoMobileHorizontal?.childImageSharp?.fluid}
          style={{ objectFit: "contain", width: "100%", height: "100%" }}
          imgStyle={{ objectFit: "contain" }}
          alt="imagem-aviso-ferias"
        />
      </div>
    </Dialog>
  )
}
